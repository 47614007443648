<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <BannerForm />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import BannerForm from '@/components/banner/BannerForm.vue';

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    BannerForm
  },
  data() {
    return {
      title: 'Banner',
      items: [
        {
          text: 'Banner Add',
          active: true
        }
      ]
    };
  }
});
</script>
